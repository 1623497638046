@media screen and (max-width: 1279px) {
  .headerBG {
    background-image: url("../../assets/images/CalcHeaderBackgroundLarge.png");
    background-position: top;
    /* background-size: cover; */
    background-repeat: no-repeat;
    position: relative;
    background-size: 1440px;
  }
}
@media screen and (min-width: 1280px) {
  .headerBG {
    background-image: url("../../assets/images/CalcHeaderBackgroundLarge.png");
    /* background-position: center; */
    /* background-size: cover; */
    background-repeat: round;
    position: relative;
  }
}

.disbursement_date {
  color: #4139ff !important;
  /* background-color: #00ffc4 !important; */
  /* border-color: #00ffc4 !important; */
}
