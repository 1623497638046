@media screen and (max-width: 1024px) {
  .details-in-payment-hub {
    border-radius: 15px;
  }
}

@media (max-width: 1399px) {
  .detailsContainer {
    height: 100%;
  }
  .customerLandingBanner {
    border-radius: 0;
    margin-top: 0;
  }
}
/* @media (min-width: 1024px) and (max-width: 1399px) {
    .detailsContainer {
      height: 100%
    }
  } */
@media (min-width: 1400px) {
  .detailsContainer {
    height: calc(100vh + 50vh);
  }
  .customerLandingBanner {
    border-radius: 0 100px 100px 100px;
  }
}

@media (max-width: 1279px) {
  .calcBox {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .calcBox {
    width: 48rem;
  }
}

.customerLandingBanner {
  background-image: linear-gradient(rgba(231, 230, 250, 0), #d9fff6 100%);
}

.calc107Container {
  width: 100%;
  max-width: 1366px;
}
