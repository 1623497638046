.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:hover {
  border: 2px solid lightgray;
}
input:focus {
  border: 2px solid #4139ff;
  color: #120270;
  outline: none;
}
input:disabled,
textarea:disabled {
  background-color: #f5f5f5;
  color: #0e0e0e;
  border: 2px solid lightgray;
}

/* Custom Styling for Date Picker */
.DayPickerInput {
  width: 100%;
}
.DayPickerInput > input {
  background-color: #fff;
  /* color: #fff; */
  /* border: 2px solid #00ffc4; */
  /* border-top: none;
  border-left: none;
  border-right: none; */
  /* text-align: center; */
  font-weight: 500;
  padding-left: 2.2rem !important;
  text-align: left;
  border: none;
  width: 100%;
  height: 3rem;
  border: 2px solid rgba(229, 231, 235, 1);
  padding: 1rem;
  border-radius: 0.75rem;
}
.DayPicker-Day {
  min-width: 2.5rem;
  padding: 0.2rem !important;
}
.DayPicker-Day--selected {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #4139ff !important;
}

.carousel .slide img {
  width: 4rem !important;
}

/* Custom Styles for Tabs */
.react-tabs__tab-list {
  width: 100%;
  display: flex;
  margin-bottom: 0;
  /* border-bottom: 1px solid #4139ff; */
  border-bottom: none;
}

.react-tabs__tab {
  padding: 1.2rem;
  color: #120270;
  font-family: "Gilroy-Bold";
  /* Below  2 lines are for tabs border when not selected */
  /* border: 1px solid lightgray; */
  /* border-bottom-color: #4139ff; */
  border-radius: 10px 10px 0 0;
  margin-right: 0.2rem;
  /* padding-bottom: 0.1rem; */
}
div#react-tabs-1 {
  border-top-left-radius: 0;
}
.react-tabs__tab--selected,
.react-tabs__tab-panel--selected {
  background-color: #e7e6fa;
}
.react-tabs__tab--selected {
  color: #4139ff;
  font-family: "Gilroy-Bold";
  border-color: #4139ff;
  border-bottom: none;
  padding: 1.2rem;
}
.react-tabs__tab-panel--selected {
  padding: 1.6rem;
  border: 1px solid #4139ff;
  /* border-top: 0; */
  border-radius: 1rem;
  text-align: left;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Carousel Slider */
@media screen and (max-width: 520px) {
  .carousel.carousel-slider {
    width: 360px !important;
  }
}
@media screen and (min-width: 521px) {
  .carousel.carousel-slider {
    height: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .carousel .slider {
    width: 100% !important;
  }
}
@media screen and (min-width: 1024px) {
  .carousel .slider {
    width: 50% !important;
  }
}
.carousel .control-dots .dot {
  opacity: 0.3 !important;
  background: #00ffc4 !important;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1 !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: unset !important;
}
.carousel .control-next.control-arrow:before {
  border-left-color: #00ffc4 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right-color: #00ffc4 !important;
}

/**
 * ==============================================
 * Dot Pulse for Loading
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 2px;
  height: 2px;
  border-radius: 20px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px #fff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 2px;
  height: 2px;
  border-radius: 20px;
  background-color: #fff;
  color: #fff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #fff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #fff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

.dot-pulse-1 {
  position: relative;
  left: -9999px;
  width: 2px;
  height: 2px;
  border-radius: 20px;
  background-color: #4139ff;
  color: #4139ff;
  box-shadow: 9999px 0 0 -5px #4139ff;
  animation: dotPulse_1 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-1::before,
.dot-pulse-1::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 2px;
  height: 2px;
  border-radius: 20px;
  background-color: #4139ff;
  color: #4139ff;
}

.dot-pulse-1::before {
  box-shadow: 9984px 0 0 -5px #4139ff;
  animation: dotPulseBefore_1 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-1::after {
  box-shadow: 10014px 0 0 -5px #4139ff;
  animation: dotPulseAfter_1 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
}

@keyframes dotPulseBefore_1 {
  0% {
    box-shadow: 9984px 0 0 -5px #4139ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #4139ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #4139ff;
  }
}

@keyframes dotPulse_1 {
  0% {
    box-shadow: 9999px 0 0 -5px #4139ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #4139ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #4139ff;
  }
}

@keyframes dotPulseAfter_1 {
  0% {
    box-shadow: 10014px 0 0 -5px #4139ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #4139ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #4139ff;
  }
}
