.pay120 {
  background-image: url("../assets/images/backgroundElepay120.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-logo {
  width: 225px;
  padding-left: 4rem;
}

.pay120 .white {
  text-align: left;
  font-size: 56px;
  color: #fff !important;
  line-height: 66px;
}

.pay120 .blue {
  font-size: 56px;
  color: #4139ff;
  line-height: 52px;
}

.pay120 .xl\:w-56 {
  z-index: 1;
}

.pay120 .border-purple {
  border-width: 2px;
  border-color: #2d78ed !important;
  background-color: #fff;
}

.pay120 h3 {
  font-size: 30px;
  color: #4139ff;
}

.pay120 h4 {
  font-size: 22px;
  color: #120270;
  text-align: left;
  line-height: 30px;
}

.pay120 h5 {
  font-size: 18px;
  color: #000;
  text-align: left;
}

.pay120 p {
  font-size: 14px;
  text-align: left;
}

.pay120 .small-font {
  font-size: 42px;
}

.pay120 .line {
  width: 100%;
  height: 2px;
  background-color: #4139ff;
}

.pay120 .img-container {
  width: 149px;
  margin-right: 20px;
}

.icon-container {
  width: 80px;
  height: 80px;
}

.pay120 .auto {
  margin: auto;
  text-align: left;
  flex: 0 0 22%;
}

.pay120 .auto p {
  padding-bottom: 10px;
}

.pay120 .block {
  flex: 1;
  text-align: center;
}

.pay120 .block h4 {
  text-align: center;
  line-height: 24px;
}

.block .img-container {
  margin: auto;
}

.pay120 .side-img {
  top: 5vh;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.pay120 .side-img img {
  position: relative;
  right: -55px;
  height: 325px;
}

.pay120 .left-line {
  border-right: 3px solid #4139ff;
}

.pay120 .detailsContainer {
  border: none;
  height: 100% !important;
}

.pay120 .details-in-payment-hub .bg-black-light {
  background: #ffffff00;
}

.pay120 .detailsContainer .details-in-payment-hub {
  text-align: left;
  color: #fff;
  padding-left: 4rem;
  padding-top: 3rem;
  background: none;
}

.pay120 .detailsContainer .details-in-payment-hub h3 {
  color: #fff;
  font-size: 14px;
}

.pay120 .detailsContainer .details-in-payment-hub p {
  color: #fff;
  font-size: 14px;
}

.pay120 .detailsContainer .details-in-payment-hub .text-purple-dark {
  color: #00ffc4;
}

.pay120 .detailsContainer .details-in-payment-hub .text-black-dark {
  color: #00ffc4;
}

.pay120 .footer .img-container {
  flex: 1;
}

.pay120 .footer .img-container img {
  width: 140px;
}

.pay120 .flex-three-container {
  flex: 1;
  margin-left: 30px;
}

.pay120 .no-interest .auto {
  flex: 0 0 28%;
  margin-left: 0;
}

.pay120 .no-interest button {
  margin-top: 2rem;
  margin-bottom: 0.7rem;
}

.pay120 .no-interest input {
  background-color: #fff;
  color: #4139ff;
  border: 2px solid rgba(229, 231, 235, 1);
  margin-top: 0;
}

.pay120 .upfront button {
  margin-top: 64px;
  margin-right: 0;
}

.pay120 .text-right {
  text-align: right !important;
}

.pay120 .DayPickerInput {
  pointer-events: none;
}

.pay120 .DayPickerInput > input {
  background-color: #f5f5f5;
}

.customer-landing .line-right {
  border-right: 2px solid #4139ff;
  padding-right: 3vw;
}

.customer-landing .line-left {
  border-left: 2px solid #4139ff;
  padding-left: 3vw;
}

.customer-landing.full-banner {
  background-image: url("../assets/images/backgroundElepay120.jpg");
  background-size: 100%;
  background-color: #e6fafb;
  background-repeat: no-repeat;
  background-position-y: -50px;
}

.customer-landing .customer-landing-text-center {
  width: 35%;
  text-align: left;
  margin: auto;
}

.customer-landing .customer-landing-text-center img {
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-right: 10px;
}

.customer-landing .customer-landing-header-text {
  width: 50%;
}

.customer-landing .constant-flex {
  display: flex;
}

.customer-landing .flex-center {
  justify-content: center;
  text-transform: uppercase;
}

.customer-landing .plan-icon {
  width: 250px;
  padding: 0;
  margin: 0 20px;
}

.customer-landing button {
  width: 100%;
}

/*Pay Now or Pay Later*/
.border-bottom {
  border-bottom: 1px solid white;
}

.new-header .flex-1 {
  flex: 1;
}

.new-header .text-green-light {
  font-size: 17px;
  letter-spacing: 1.55px;
}

.new-header .text-6xl {
  font-size: 56px;
}

.new-header .text-2xl {
  font-size: 22px;
}

.pl-large {
  padding-left: 10rem;
}

.pr-large {
  padding-right: 10rem;
}

.new-header .image-large {
  width: 385px;
}

.pay-now-pay-later-form .card {
  background-color: #fff;
  padding: 36px;
  border-radius: 14px;
  border: 2px solid #fff;
  position: relative;
}

.pay-now-pay-later-form .card h2 {
  font-size: 30px;
}

.pay-now-pay-later-form .card h3 {
  font-size: 22px;
}

.pay-now-pay-later-form .card p {
  font-size: 16px;
}

.pay-now-pay-later-form .card .text-md {
  font-size: 14px;
}

.pay-now-pay-later-form .card.active {
  box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
  -webkit-box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
  -moz-box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
}

.pay-now-pay-later-form .card .tag {
  position: relative;
  text-align: left;
  width: 118px;
  border-radius: 0 5px 5px 0;
  left: -38px;
  padding: 4px 0;
  padding-left: 36px;
  margin-bottom: 25px;
  background-color: #e8e5ff;
  color: #4139ff;
}

.pay-now-pay-later-form .step-3-block .mr-4 {
  margin-right: 18px;
}

.pay-now-pay-later-form .card.active .tag {
  left: -38px;
  background-color: #4139ff;
  color: #fff;
  text-align: left;
}

.pay-now-pay-later-form .m-a {
  margin: auto;
}

.pay-now-pay-later-form .flex-1 {
  flex: 1;
}

.pay-now-pay-later-form .flex-3 {
  flex: 3;
}

.pay-now-pay-later-form .card .vertical-line {
  height: 91%;
  background: #4139ff;
  width: 2px;
  margin: auto;
}
.pay-now-pay-later-form .less-bottom {
  position: relative;
  bottom: -10px;
}

.pay-now-pay-later-form .vertical-container {
  padding: 0 30px;
}

.pay-now-pay-later-form .vertical-container p {
  padding-bottom: 20px;
  padding-top: 8px;
}

.pay-now-pay-later-form .less-bottom ul {
  width: 350px;
  margin: auto;
}

.pay-now-pay-later-form .less-bottom ul li {
  width: 350px;
}

.pay-now-pay-later-form .less-bottom ul li div.flex.relative {
  width: 175px;
  text-align: center;
}

.pay-now-pay-later-form .less-bottom ul li a {
  width: 100%;
  font-size: 16px !important;
}

.pay-now-pay-later-form .less-bottom ul li a.text-purple-light {
  color: #000;
}

.pay-now-pay-later-form form .form {
  justify-content: center;
}

.pay-now-pay-later-form .text-4xl {
  font-size: 42px;
  margin-bottom: 36px;
}

.pay-now-pay-later-form .step-1-block {
  max-width: 470px;
  flex: 1;
}

.pay-now-pay-later-form .step-2-block {
  max-width: 936px;
  flex: 1;
}

.pay-now-pay-later-form .step-2-block.constant-width {
  max-width: 470px !important;
}

.pay-now-pay-later-form .step-3-block {
  max-width: 972px;
  width: 972px;
}

.pay-now-pay-later-form .step-1-block .top-button {
  border: 2px solid #fff;
  position: absolute;
  bottom: 36px;
  width: 350px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.pay-now-pay-later-form .tag.active {
  left: -38px;
  background-color: #4139ff;
  color: #fff;
}

.pay-now-pay-later-form .tag-3 {
  text-align: left;
  width: 210px !important;
}

.pay-now-pay-later-form .full-tab {
  width: 350px;
  height: 52px;
  margin: 30px auto 40px auto;
  background: #4139ff;
  border: solid 2px #4139ff;
  border-radius: 30px;
  padding: 15px;
  color: #fff;
  box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.15) inset;
  -webkit-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.15) inset;
  -moz-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.15) inset;
}

.pay-now-pay-later-form input {
  height: 52px;
  font-size: 22px;
}

.pay-now-pay-later-form .card .indent {
  border: none !important;
  color: #fff;
  background: #2e13cc;
  border: solid 2px #2e13cc;
  box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.15) inset;
  -webkit-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.15) inset;
  -moz-box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.15) inset;
}

.pay-now-pay-later-form .card button.indent:hover {
  border: none !important;
}

.pay-now-pay-later-form button {
  height: 52px;
  border-radius: 52px;
  /* border: none; */
  /* box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.25); */
}

.pay-now-pay-later-form button:hover {
  border: 2px solid #4139ff !important;
  /* box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
  -webkit-box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
  -moz-box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75); */
}

.pay-now-pay-later-form .step-2-block {
  text-align: center;
}

.pay-now-pay-later-form .step-2-block .flex {
  flex-direction: row;
}

.pay-now-pay-later-form .step-2-block .flex img {
  width: 70px;
}

.pay-now-pay-later-form .step-2-block .less-bottom .border-purple-light {
  border-radius: 30px;
}

.pay-now-pay-later-form .pay-options-block .flex-col {
  flex-direction: column;
}

.pay-now-pay-later-form .step-2-block button {
  border: 2px solid #fff;
  width: 350px;
  padding: 10px;
}

.pay-now-pay-later-form .small-button {
  width: 350px;
}

.pay-now-pay-later-form .plan-selector {
  height: 100px;
  justify-content: center;
}

.pay-now-pay-later-form .plan-selector .plan-selector-container {
  width: 90px;
  margin: auto;
  position: relative;
  top: 0;
  transition: all 0.5s ease-in-out;
}

.pay-now-pay-later-form .plan-selector.selected {
  height: 150px !important;
}

.pay-now-pay-later-form .plan-selector .large {
  width: 160px !important;
}

.pay-now-pay-later-form .plan-selector .plan-selector-container.active {
  width: 115px;
  border-radius: 75px;
  top: -6px;
  transition: all 0.5s ease-in-out;
}

.pay-now-pay-later-form .plan-selector .plan-selector-container.active img {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.pay-now-pay-later-form .plan-selector .plan-selector-container img {
  width: 100%;
  opacity: 0.5;
  -webkit-filter: drop-shadow(0 5px 5px #707070);
  filter: drop-shadow(0 5px 5px #00000057);
  transition: all 0.5s ease-in-out;
}

.pay-now-pay-later-form .card.no-click {
  pointer-events: none;
}

.pay-now-pay-later-form .card.no-click button {
  opacity: 0.5;
}

.pay-now-pay-later-form .card.no-click .amount {
  opacity: 0.3;
}

.pay-now-pay-later-form .chevron::before {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  border-color: #4139ff;
  content: "";
  display: inline-block;
  height: 1.3em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 1.3em;
}

.pay-now-pay-later-form select::-ms-expand {
  display: none;
}

.pay-now-pay-later-form .chevron.chev-right:before {
  left: 0;
  transform: rotate(45deg);
}

.pay-now-pay-later-form .chevron.chev-left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.dash-line {
  color: #4139ff;
  padding: 0 10px;
}

.dash-line:nth-child(4) {
  display: none;
}

.calc-popup {
  position: fixed;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 15;
  justify-content: center;
  margin: auto;
  display: flex;
}

.calc-popup .disabled {
  pointer-events: none;
}

.calc-popup.continue input {
  font-weight: 700;
  color: #4139ff;
}

.calc-popup.continue .m-a {
  margin: auto;
}

.calc-popup.continue .popup-container {
  display: flex;
}

.calc-popup.continue .popup-container input {
  font-size: 22px;
}

.calc-popup.continue .popup-container h3 {
  font-size: 22px;
}

.calc-popup.continue .popup-container h1 {
  font-size: 42px;
}

.calc-popup.continue .popup-container .text-sm {
  font-size: 14px;
}

.calc-popup .popup-container {
  width: 510px;
  background: #fff;
  border-radius: 14px;
  border: 2px solid #4139ff;
  margin: auto;
  padding: 36px;
}

.calc-popup .popup-container .w-100 {
  width: 100%;
}

.calc-popup .popup-container span .w-20 {
  width: 80px;
}

.calc-popup .popup-container h2 {
  font-size: 30px;
}

.calc-popup .popup-container .image-container {
  width: 160px;
  margin: 42px auto 20px auto;
}

.calc-popup.continue .popup-container .image-container {
  width: 230px;
  margin: 42px 30px 20px auto;
}

.calc-popup .popup-container p {
  font-size: 16px;
}

.calc-popup .popup-container h1 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.calc-popup .popup-container .image-container img {
  width: 100%;
}

.calc-popup .popup-container button {
  border-radius: 52px;
  height: 52px;
  width: 100%;
  margin: 0 5px;
  border-width: 2px;
}

.pay-now-pay-later-form .paynow-paylater-selected {
  width: 100%;
  margin: auto;
  padding: 0;
  padding-top: 2.5rem;
  justify-content: center;
}

.pay-now-pay-later-form .paynow-paylater-selected .card.flex-3 {
  flex: 1 !important;
  max-width: 470px;
}

.pay-now-pay-later-form .paynow-paylater-selected .hidden {
  display: none;
}

.pay-now-pay-later-form .change-option {
  position: absolute;
  right: 36px;
  top: 45px;
  cursor: pointer;
}

.pay-now-pay-later-form .change-option p {
  font-size: 12px;
  text-decoration: underline;
}

.pay-now-pay-later-form .less-bottom li {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15) inset;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15) inset;
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15) inset;
}

.pay-now-pay-later-form .less-bottom .bg-purple-light {
  border-radius: 30px !important;
  box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
  -webkit-box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
  -moz-box-shadow: 0px 1px 13px 0px rgba(65, 57, 255, 0.75);
}

.pay-now-pay-later-form .pay-modal {
  background: #000000c9;
}

.calc-popup.continue .button-container {
  width: 100%;
}

.calc-popup.continue .justify-start {
  margin-right: auto;
}

.top-banner div {
  padding-left: 10rem;
}

.pay-modal {
  background: #000000aa;
}

.pay-now-pay-later-form .dollar-container span {
  font-size: 24px;
  color: #4139ff;
  font-weight: 700;
}

.pay-now-pay-later-form .dollar-container input {
  padding-left: 27px;
}

.pay-now-pay-later-form .dollar-container .absolute {
  top: 1px;
}

.pay-now-pay-later-form .step-2-block .flex .direct-debit-img {
  width: 94px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.pay-now-pay-later-form .extra-small-text {
  font-size: 8px !important;
  color: #b9b9b9;
}

.pay-now-pay-later-form .input.no-click {
  pointer-events: none;
  width: 100%;
}

@media (max-width: 1700px) {
  .customer-landing.full-banner {
    background-size: 115%;
    background-position-y: 0;
  }

  .pay-now-pay-later-form .step-1-block .top-button {
    width: 265px;
    padding: 0.75rem 0;
  }
}

@media (max-width: 1580px) {
  .customer-landing.full-banner {
    background-size: 120%;
  }

  .pr-large {
    padding-right: 4rem;
  }

  .pl-large {
    padding-left: 4rem;
  }
}

@media (max-width: 1440px) {
  .customer-landing.full-banner {
    background-size: 140%;
  }
}

@media (max-width: 1400px) {
  .customer-landing h1 {
    font-size: 2.75rem;
  }

  .customer-landing p {
    font-size: 1.3rem;
  }

  .customer-landing .card p {
    font-size: 0.85rem;
  }

  .customer-landing .tag p {
    font-size: 1rem;
  }

  .new-header .image-large {
    width: 380px;
  }

  .pay-now-pay-later-form .plan-selector .plan-selector-container {
    width: 60px;
  }

  .pay-now-pay-later-form .plan-selector .plan-selector-container.active {
    width: 85px;
  }

  .pay-now-pay-later-form .chevron::before {
    width: 15px;
    height: 15px;
  }

  .pay-now-pay-later-form .step-2-block {
    padding: 25px;
  }

  .pay-now-pay-later-form .card.step-2-block .tag {
    left: -28px;
  }
}

@media (max-width: 1280px) {
  .pay-now-pay-later-form .tag.active {
    left: -28px;
  }

  .pay-now-pay-later-form .card .tag {
    left: -28px !important;
  }

  .pay-now-pay-later-form form .form {
    flex-direction: column;
  }

  .pay-now-pay-later-form form .form .step-1-block {
    margin: auto;
    margin-bottom: 25px;
  }

  .pay-now-pay-later-form form .form .step-2-block {
    margin: auto;
    margin-bottom: 25px;
    max-width: 972px;
  }

  .pay-now-pay-later-form .step-1-block .top-button {
    position: initial;
    width: 100%;
  }

  .pay-now-pay-later-form .plan-selector {
    width: 300px;
    margin: auto;
  }

  .pay-now-pay-later-form h3.text-xl {
    margin-top: 22px;
  }

  .pay-now-pay-later-form .card h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .pay-now-pay-later-form .card {
    padding: 25px;
  }

  .pay-now-pay-later-form .m-a {
    margin: 0;
  }

  .pl-large {
    padding-left: 3rem;
  }

  .pr-large {
    padding-right: 3rem;
  }

  .pay-now-pay-later-form .less-bottom li a {
    font-size: 12px;
    padding: 10px 15px;
  }

  .customer-landing h1 {
    font-size: 2.25rem;
  }

  .customer-landing.full-banner {
    background-size: 160%;
  }

  .pay120 .white {
    margin-bottom: 15px;
  }

  .pay120 {
    background-size: 2300px;
  }

  .pay120 .xl\:w-56 {
    display: none;
  }

  .pay120 .detailsContainer .details-in-payment-hub {
    margin: auto;
    width: 350px;
    padding-left: 4rem;
    padding-top: 0;
  }

  .pay120 .xl\:w-56 {
    position: absolute;
    width: 98%;
    top: 140px;
  }

  .pay120 .border-purple {
    margin-left: 10px;
    margin-right: 10px;
  }

  .pay120 .side-img {
    position: relative;
    top: 0;
    right: 0;
    overflow: initial;
  }

  .pay120 .smaller-margin {
    margin-top: 0;
  }

  .pay120 .side-img img {
    right: 0;
    height: 250px;
  }

  .customer-landing .constant-flex .xl\:pl-16 {
    padding-left: 4rem;
  }
}

@media (max-width: 1140px) {
  .pr-large {
    padding-right: 4rem;
  }

  .pl-large {
    padding-left: 4rem;
  }

  .customer-landing.full-banner {
    background-size: 210%;
  }

  .pay-now-pay-later-form .step-1-block .top-button {
    width: 90%;
  }

  .pay-now-pay-later-form .less-bottom {
    font-size: 12px;
  }

  .pay-now-pay-later-form .text-3xl {
    font-size: 20px;
    line-height: 22px;
  }

  .pay-now-pay-later-form .customer-landing p {
    font-size: 16px;
  }

  .pay-now-pay-later-form h3.text-xl {
    font-size: 16px;
    line-height: 18px;
  }

  .pay-now-pay-later-form p {
    font-size: 16px;
    line-height: 18px;
  }

  .customer-landing h1 {
    font-size: 36px;
  }

  .pay-now-pay-later-form .change-option p {
    width: 205px;
    text-align: right;
  }

  .pay-now-pay-later-form .change-option {
    top: 20px;
  }
}

@media (max-width: 1024px) {
  .pay-now-pay-later-form .step-3-block {
    width: 100%;
  }

  .customer-landing .customer-landing-text-center {
    width: 100%;
  }

  .pl-large {
    padding-left: 2rem;
  }

  .pr-large {
    padding-right: 2rem;
  }
}

@media (max-width: 950px) {
  .w-4\/5 {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .customer-landing.full-banner {
    background-size: 1950px;
  }

  .new-header .image-large {
    width: 345px;
  }

  .customer-landing h1 {
    font-size: 2.5rem;
  }

  .customer-landing p {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .pay-now-pay-later-form .step-1-block .top-button {
    position: relative;
    width: 100%;
    margin-top: 45px;
  }

  .pay-now-pay-later-form .card {
    margin-bottom: 25px;
    width: 100%;
  }

  .pay-now-pay-later-form .card .date-section {
    flex-direction: row;
  }
}

@media (max-width: 860px) {
  .customer-landing h1 {
    font-size: 2.3rem;
  }

  .new-header .image-large {
    width: 300px;
  }

  .pl-large {
    padding-left: 3rem;
  }

  .pr-large {
    padding-right: 3rem;
  }
}

@media (max-width: 835px) {
  .pay-now-pay-later-form .flex {
    flex-direction: column;
  }

  .pay-now-pay-later-form .new-header {
    text-align: center;
    padding-top: 0.5rem;
  }

  .pay-now-pay-later-form .new-header .text-left {
    text-align: center;
  }

  .new-header .image-large {
    padding-top: 2rem;
  }

  .pay120 .white {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    position: relative;
  }

  .pay120 .blue {
    font-size: 36px;
  }

  .pay120 .xl\:w-56 {
    top: 105px;
  }

  .pay120 .top-logo {
    width: 150px;
    padding-left: 0;
    margin: auto;
    padding-bottom: 10px;
  }

  .customer-landing .customer-landing-header-text {
    width: 100%;
  }

  .customer-landing .plan-icon {
    width: 25vw;
    margin: 0px 15px;
  }

  .pay-now-pay-later-form .less-bottom ul {
    width: 100%;
  }

  .pay-now-pay-later-form .less-bottom ul li {
    width: 100%;
  }

  .pay-now-pay-later-form .less-bottom ul li div.flex.relative {
    width: 100%;
  }

  .pay-now-pay-later-form .step-2-block button {
    width: 100%;
  }

  .pay-now-pay-later-form .plan-selector {
    width: 100%;
  }

  .pay-now-pay-later-form .step-3-block .mr-4 {
    margin-right: 0;
  }
}

@media (max-width: 730px) {
  .pay120 .no-interest .flex-three-container .flex {
    display: block;
  }

  .pay120 .no-interest .img-container {
    margin: auto;
  }

  .customer-landing .flex.justify-center.details {
    display: block;
  }

  .customer-landing .line-right {
    border-right: none;
    padding-right: 0;
  }

  .customer-landing .line-left {
    border-left: none;
    padding-left: 0;
  }

  .customer-landing .constant-flex .xl\:pl-16 {
    padding-left: 1rem;
    font-size: 22px;
  }

  .pay-now-pay-later-form .card.step-2-block .step-2-container {
    flex-direction: column;
  }

  .pay-now-pay-later-form
    .card.step-2-block
    .step-2-container
    .vertical-container {
    padding: 30px 30px 0 30px;
  }
}

@media (max-width: 545px) {
  .pr-large {
    padding-right: 1rem;
  }

  .pl-large {
    padding-left: 1rem;
  }

  .pay120 .white {
    font-size: 30px;
    line-height: 35px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .pay120 .blue {
    line-height: 40px;
  }

  .pay120 .small-font {
    font-size: 28px;
    line-height: 32px;
  }

  .pay120 .side-img {
    top: 10px;
  }

  .pay120 .side-img img {
    right: -20px;
    height: 150px;
    margin: auto;
    margin-bottom: 10px;
  }

  .pay120 {
    background-position: center top;
    background-size: cover;
  }

  .pay120 .img-container {
    width: 150px;
    margin: 15px auto !important;
  }

  .pay120 button {
    margin-top: 24px !important;
  }

  .pay120 p {
    text-align: center;
  }

  .pay120 .icon-container {
    width: 45px;
    height: 45px;
  }

  .pay120 .block h4 {
    font-size: 14px;
    line-height: 18px;
  }

  .pay120 .flex-wrap {
    justify-content: center;
    flex-direction: column;
  }

  .pay120 .auto {
    margin: 15px auto !important;
    flex: 1;
  }

  .pay120 .footer {
    display: block !important;
    text-align: center;
  }

  .pay120 .footer .terms-container p {
    text-align: center;
  }

  .pay120 .footer .img-container {
    margin: auto;
    margin-bottom: 24px !important;
  }

  .pay120 .footer .terms-container.m-10 {
    margin: 0 !important;
    padding-bottom: 24px;
  }

  .pay120 .detailsContainer .details-in-payment-hub {
    width: 260px;
  }

  .pay120 .xl\:w-56 {
    margin-top: 0 !important;
    top: 75px;
  }

  .pay120 .xl\:w-56 {
    width: 90%;
  }

  .pay120 .no-interest .remove-flex {
    display: block;
  }

  .w-4\/5 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .calc-popup .popup-container {
    width: 325px;
  }

  .calc-popup.continue .m-a {
    margin: 0;
  }

  .calc-popup.continue .popup-container {
    overflow-y: scroll;
    flex-direction: column;
  }

  .calc-popup .button-container {
    flex-direction: column;
  }

  .calc-popup .button-container button {
    margin: 10px;
  }

  .calc-popup.continue .popup-container span.flex {
    flex-direction: column;
  }

  .calc-popup.continue .popup-container span.flex img {
    width: 115px;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .pay120 .xl\:w-56 {
    top: 90px;
  }

  .pay-now-pay-later-form .small-button {
    width: 100%;
  }

  .pay-now-pay-later-form .full-tab {
    width: 100%;
  }

  .pay-now-pay-later-form .change-option {
    top: 20px;
  }
}

@media (max-width: 340px) {
  .pay-now-pay-later-form .step-2-block button {
    width: 180px;
    padding: 5px 0;
  }

  .pay-now-pay-later-form .new-header h1 {
    font-size: 2.2rem;
  }

  .pay-now-pay-later-form .new-header h3 {
    padding-top: 0.5rem;
  }

  .pay-now-pay-later-form .top-banner {
    padding: 0 !important;
  }

  .pay-now-pay-later-form .top-banner .flex {
    padding: 15px !important;
  }

  .pay120 .icon-container {
    width: 40px;
    height: 40px;
  }

  .pay120 .block h4 {
    font-size: 12px;
    line-height: 16px;
  }

  .customer-landing .w-80 {
    width: 15rem;
  }

  .customer-landing .plan-icon {
    width: 100px;
    margin: 0px 5px;
  }

  .customer-landing .text-6xl {
    font-size: 2.75rem;
  }

  .customer-landing .text-5xl {
    font-size: 2.6rem;
  }

  .customer-landing .text-2xl {
    font-size: 1.2rem;
  }

  .customer-landing .text-xl {
    font-size: 1.1rem;
  }
}

@media (max-width: 325px) {
  .pay120 .xl\:w-56 {
    top: 110px;
  }

  .pay120 .no-interest .flex {
    display: block;
  }

  .pr-large {
    padding-right: 1rem;
  }

  .pl-large {
    padding-left: 1rem;
  }

  .calc-popup .popup-container {
    height: 725px;
    width: 290px;
  }
}

@media (max-height: 750px) {
  .calc-popup {
    overflow: scroll;
  }
}
