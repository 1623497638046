body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Adding the tailwind styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Gilroy-Heavy";
    src: url("./assets/fonts/Gilroy/Gilroy-Heavy.woff2") format("woff2");
  }
  @font-face {
    font-family: "Gilroy-Bold";
    src: url("./assets/fonts/Gilroy/Gilroy-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: "Gilroy-Semibold";
    src: url("./assets/fonts/Gilroy/Gilroy-Semibold.woff2") format("woff2");
  }
  @font-face {
    font-family: "Gilroy-Regular";
    src: url("./assets/fonts/Gilroy/Gilroy-Regular.woff2") format("woff2");
  }
}
