@media screen and (max-width: 1024px) {
  .details-in-payment-hub {
    border-radius: 15px;
  }
}

@media (max-width: 1399px) {
  .detailsContainer {
    height: 100%;
  }
}

.elepay-footer-logo {
  position: absolute;
  bottom: 2rem;
  width: 174px;
  height: 43px;
}

.footer-rights {
  font-size: 15px;
  font-family: 'Gilroy-Regular';
}

.coronis-landing.full-banner {
  background-color: #f7f8f9;
  background-repeat: no-repeat;
}

.header-coronis-banner {
  background-color: #37ae9a;
  padding: 2rem;
}

.header-table {
  width: 100%;
}

.header-column-coronis {
  padding-left: 3.8rem;
}

.header-column-coronis > img {
  width: 203px;
}

.header-column-elepay {
  float: right;
  padding-right: 3.8rem;
}

.header-column-full-elepay {
  width: 277px;
}

.header-column-butterfly {
  display: none;
}

.coronis-pay-now-pay-later-form .less-bottom {
  font-size: 14px;
}

.coronis-pay-now-pay-later-form .less-bottom > div > div {
  margin-bottom: 1rem;
}

.coronis-pay-now-pay-later-form .less-bottom > div > div > div > ul {
  padding-top: 20px;
}

.greeting > h1 {
  color: white;
  position: absolute;
  left: 5.6rem;
  top: 13.4rem;
  font-size: 2.5rem;
  font-family: 'Gilroy-Bold';
}

.manageHeading > h1 {
  color: white;
  position: absolute;
  left: 5.6rem;
  top: 17.4rem;
  font-size: 3.49rem;
  font-family: 'Gilroy-Bold';
  width: 75%;
  text-align: left;
  line-height: 4.5rem;
}

.details-container {
  width: 100%;
  height: 190px;
}

.details-container-floating {
  position: absolute;
  background-color: #37ae9a;
  width: 940px;
  border-radius: 1rem;
  bottom: 58.7rem;
  left: 15.6rem;
  padding: 1.9rem 3.8rem 1.9rem 3.8rem;
  font-family: 'Gilroy-Regular';
}

.details-table {
  width: 100%;
  display: flex;
}

.details-column {
  border-right: 1.5px solid #fff;
  text-align: left;
  width: 50%;
}

.details-column > h1 {
  color: #fff;
  padding-bottom: 0.6rem;
  font-family: 'Gilroy-Bold';
  font-size: 1.2rem;
}

.details-column > div {
  color: #fff;
}

.details-column-data {
  font-family: 'Gilroy-Bold';
  padding-bottom: 0.85rem;
  margin-top: -0.25rem;
  font-size: 0.99rem;
}

.details-column-heading {
  font-size: 0.79rem;
  font-weight: bold;
}

.details-column-data.last-row {
  padding-bottom: 0rem;
}

.payment-column {
  padding-left: 5rem;
  vertical-align: top;
}

.payment-table {
  text-align: left;
  color: #fff;
}

.payment-header > h1 {
  padding-bottom: 2.5rem;
  font-family: 'Gilroy-Bold';
  font-size: 1.2rem;
}

.payment-row {
  border-bottom: 1px dashed #50b9a8;
  font-size: 1rem;
  font-weight: bold;
}

.payment-table > tr:last-child {
  border-bottom: none;
  font-family: 'Gilroy-Bold';
  font-size: 1.1rem;
}

.payment-heading-column {
  width: 17rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.payment-heading-column.first-row {
  padding-top: 0;
}

.payment-heading-column.last-row {
  padding-bottom: 0;
}

.payment-amount-column {
  text-align: right;
}

.payment-amount-column.last-row {
  padding-top: 0.8rem;
}

.payment-container {
  display: flex;
  margin-left: 15.6rem;
  padding-bottom: 100px;
}

.pay-later-container {
  background-color: #fff;
  border-radius: 1rem;
  width: 460px;
  height: 650px;
}

.pay-later-container > div > h2 {
  padding-top: 37px;
  padding-bottom: 20px;
}

.pay-later-container > div > img {
  width: 172.8px;
  height: 173.28px;
  margin-left: 9rem;
}

.pay-later-container > div > p {
  font-size: 16px;
  margin-top: 30px;
}

.pay-later-date-container {
  display: flex;
  font-family: 'Gilroy-Bold';
  font-size: 22px;
  color: #37ae9a;
  padding-top: 8px;
}

.pay-later-date {
  width: 40%;
  text-align: right;
  padding-right: 5px;
}

.pay-later-stroke {
  padding: 15px 10px 0px 10px;
}

.pay-later-stroke > hr {
  border-top-color: #37ae9a;
  width: 64.67px;
}

.pay-later-amount {
  padding-left: 5px;
}

.pay-later-button-container {
  padding-top: 41px;
  padding-bottom: 16px;
}

.pay-later-button-container > button {
  width: 396px;
  border-radius: 40px !important;
  box-shadow: 0px 8px 15px #00000021;
}

.pay-later-button-container > button:hover {
  border: 2px solid #37ae9a !important;
}

.conditions-apply {
  font-size: 6px;
  padding-bottom: 17px;
}

.fees-capped-container {
  font-size: 11px;
  color: #fff;
  padding: 7px 19px 7px 19px;
  background-color: #37ae9a;
  margin: 5px 60px 0 60px;
  border-radius: 4px;
}

.pay-early-options-heading {
  font-size: 16px;
  font-family: 'Gilroy-Regular';
  font-weight: bold;
  padding-top: 32px;
  padding-bottom: 11px;
}

.pay-early-options-heading > span {
  font-family: 'Gilroy-Bold';
  color: #37ae9a;
}

.pay-early-options-table {
  margin-left: 115px;
}

.pay-early-options-table > tr:first-child {
  border-bottom: 0.17px solid #707070;
}

.pay-early-options-date {
  font-family: 'Gilroy-Regular';
  font-weight: bold;
  font-size: 13px;
  width: 165px;
  text-align: left;
}

.pay-early-options-amount {
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  text-align: left;
}

.payment-divider {
  width: 20px;
}

.pay-now-container {
  background-color: #fff;
  border-radius: 1rem;
  width: 460px;
  height: 650px;
}

.pay-now-container > div > h2 {
  padding-top: 37px;
  padding-bottom: 14px;
}

.pay-now-content > .bank-transfer-table-container > table {
  border: 1px solid #ABABAB5E;
  border-collapse: collapse;
  width: 90%;
  margin-left: 1.5rem;
  margin-bottom: 31px;
}

.pay-now-content > .bank-transfer-table-container > table > tr {
  border-bottom: 1px solid #ABABAB5E;
}

.bank-transfer-heading {
  font-size: 14px;
  font-family: 'Gilroy-Regular';
  font-weight: bold;
  text-align: right;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-right: 1px solid #ABABAB5E;
}

.bank-transfer-data {
  font-size: 16px;
  font-family: 'Gilroy-Bold';
  text-align: left;
  color: #37ae9a;
  padding-left: 5px;
}

.pay-now-content > .bank-transfer-table-container > table > tr:last-child {
  border-bottom: none;
}

.bank-transfer-notify-text {
  font-family: 'Gilroy-Regular';
  font-weight: bold;
  font-size: 11px;
}

.bank-transfer-pay-today-text {
  margin-top: 30px;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
}

.bank-transfer-amount-text {
  font-family: 'Gilroy-Bold';
  font-size: 42px;
  color: #37ae9a;
  padding-top: 10px;
  padding-bottom: 24px;
}

.credit-card-amount-text {
  font-family: 'Gilroy-Bold';
  font-size: 42px;
  color: #37ae9a;
  padding-bottom: 24px;
}

.direct-debit-amount-text {
  font-family: 'Gilroy-Bold';
  font-size: 42px;
  color: #37ae9a;
  padding-bottom: 22px;
}

.cards-container {
  margin-left: 9.5rem;
}

.fee-applies {
  font-size: 12px;
  color: #707070;
  padding-top: 29.78px;
  margin-bottom: 9px;
}

.direct-debit-fee-applies {
  font-size: 12px;
  color: #707070;
  margin-top: 51.67px;
  margin-bottom: 9px;
}

.direct-debit-img {
  width: 176.34px;
  margin-left: 9rem;
  margin-top: 3rem;
}

.pay-now-button-container {
  padding-bottom: 25px;
}

.pay-now-button-container > button {
  width: 396px;
  border-radius: 40px !important;
  box-shadow: 0px 8px 15px #00000021;
}

.pay-now-button-container > button:hover {
  border: 2px solid #37ae9a !important;
}

.bank-transfer-popup {
  position: fixed;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 15;
  justify-content: center;
  margin: auto;
  display: flex;
}

.bank-transfer-popup.continue .popup-container {
  display: flex;
}

.bank-transfer-popup .popup-container {
  width: 565.11px;
  background: #fff;
  border-radius: 14px;
  border: 2px solid #37ae9a;
  margin: auto;
}

.banner-image {
  border-radius: 14px;
  height: 255px;
  width: 565.11px;
  object-fit: cover;
}

.bank-transfer-thank-you {
  position: absolute;
  left: 39%;
  top: 25%;
  font-size: 66px;
}

.bank-transfer-once-cleared-text {
  padding-top: 30px;
  font-size: 22px;
  line-height: 25px;
}

.bank-transfer-more-funding {
  padding-top: 20px;
  color: #37ae9a;
  font-size: 18px;
  line-height: 22px;
}

.bank-transfer-button-container {
  width: 100%;
}

.bank-transfer-button-container > table {
  width: 100%;
}

.bank-transfer-button-container > table > tr > td {
  padding-top: 40px;
  padding-bottom: 40px;
}

.bank-transfer-button-container > table > tr > td:first-child {
  text-align: right;
  padding-right: 15px;
}

.bank-transfer-button-container > table > tr > td:last-child {
  text-align: left;
  padding-left: 15px;
}

.bank-transfer-button-container > table > tr > td > button {
  width: 230.98px;
  border-radius: 40px !important;
  box-shadow: 0px 3px 6px #00000021;
}

.bank-transfer-button-container > table > tr > td > button:hover {
  background-color: #00AE9A;
  border-color: #00AE9A;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000021;
  color: #fff;
}

.error-message > h1 {
  color: white;
  position: absolute;
  left: 5.5rem;
  top: 20rem;
  font-size: 2.5rem;
  font-family: 'Gilroy-Bold';
  width: 75%;
  text-align: left;
  line-height: 4.5rem;
}

@media only screen and (max-width: 425px) {
  .coronis-landing.full-banner {
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 0;
  }

  .header-coronis-banner {
    align-items: flex-start;
    padding: 1rem 1rem 1rem 2rem;
  }

  .header-column-coronis {
    padding-left: 0;
  }
  
  .header-column-coronis > img {
    width: 115px;
  }

  .greeting > h1 {
    font-size: 26px;
    top: 7rem;
    left: 2rem;
  }

  .manageHeading > h1 {
    font-size: 32px;
    line-height: 2.4rem;
    left: 2rem;
    top: 10rem;
    max-width: 100%;
  }

  .header-column-elepay {
    padding-right: 0;
  }
  
  .header-column-full-elepay {
    display: none;
  }

  .header-column-butterfly {
    display: block;
    width: 35px;
  }

  .banner-container {
    width: 100%;
    align-items: start;
    height: 250px;
  }

  .banner-container > img {
    height: 100%;
    object-fit: cover;
    object-position: 45% top;
    max-width: 100%;
  }

  .details-container {
    max-width: 100%;
    overflow-x: hidden;
    height: 465px;
  }

  .details-container-floating {
    width: 85%; 
    top: 18rem;
    bottom: 0;
    left: 2rem;
    padding: 35px 20px 20px 40px;
    height: 475px;
  }

  .details-table {
    display: block;
  }

  .details-column {
    border-right: none;
    text-align: left;
    width: auto;
    border-bottom: 1.5px solid #fff;
    padding-bottom: 20px;
  }

  .details-column > h1 {
    font-size: 1rem;
  }

  .details-column-heading {
    font-size: 12px;
  }
  
  .details-column-data {
    font-size: 14px;
  }

  .details-column-data.last-row {
    padding-bottom: 0rem;
  }

  .payment-column {
    padding-left: 0;
    padding-top: 20px;
    vertical-align: top;
  }

  .payment-header > h1 {
    padding-bottom: 2rem;
    font-size: 1rem;
  }

  .payment-row {
    font-size: 0.8rem;
    letter-spacing: 0.8px;
  }

  .payment-table > tr:last-child {
    font-size: 0.9rem;
  }

  .payment-table > tr:last-child > .payment-amount-column {
    padding-top: 0.4rem;
  }

  .payment-heading-column {
    width: 17rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .payment-container {
    margin-left: 2rem;
    display: block;
    width: 85%;
    padding-bottom: 50px;
  }

  .pay-later-container {
    width: auto;
    height: 565px;
  }
  
  .pay-later-container > div > h2 {
    font-size: 1.5rem;
  }
  
  .pay-later-container > div > img {
    width: 150px;
    height: 151px;
    margin-left: 5rem;
  }
  
  .pay-later-container > div > p {
    font-size: 14px;
    margin-top: 25px;
  }

  .pay-later-date-container {
    font-size: 16px;
  }

  .pay-later-date {
    width: 36%;
  }

  .fees-capped-container {
    font-size: 10px;
    padding: 7px 0 7px 0;
    margin: 5px 15px 5px 15px;
  }

  .pay-early-options-heading {
    font-size: 13px;
    padding-top: 26px;
    padding-bottom: 8px;
  }

  .pay-early-options-table {
    margin-left: 64px;
  }

  .pay-early-options-date {
    font-size: 11px;
    width: 140px;
  }
  
  .pay-early-options-amount {
    font-size: 12px;
  }

  .pay-later-button-container {
    padding-top: 20px;
  }
  
  .pay-later-button-container > button {
    width: 90%;
  }

  .payment-divider {
    width: 0;
    height: 20px;
  }

  .pay-now-container {
    width: auto;
    height: 565px;
  }
  
  .pay-now-container > div > h2 {
    font-size: 1.5rem;
  }

  .pay-now-container > div > p {
    font-size: 14px;
  }

  .coronis-pay-now-pay-later-form .less-bottom {
    font-size: 9.5px;
  }

  .coronis-pay-now-pay-later-form .less-bottom > div > div > div > ul {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .coronis-pay-now-pay-later-form .less-bottom > div > div > div > ul > li {
    width: 300px !important;
    height: 42px !important;
  }

  .coronis-pay-now-pay-later-form .less-bottom > div > div > div > ul > li > div > a {
    width: 100px !important;
  }

  .pay-now-content > p {
    font-size: 13px;
    margin-bottom: 1rem;
  }

  .cards-container {
    margin-left: 5.5rem;
  }

  .fee-applies {
    font-size: 12px;
    padding-top: 16px;
    margin-bottom: 5px !important;
  }

  .credit-card-amount-text {
    padding-bottom: 5px;
  }

  .direct-debit-img {
    margin-left: 4.5rem;
    margin-top: 2.8rem;
  }

  .direct-debit-fee-applies {
    margin-bottom: 0.5rem;
    margin-top: 20px;
    margin-bottom: 1.2rem;
  }

  .direct-debit-amount-text {
    padding-bottom: 5px;
  }

  .pay-now-content > .bank-transfer-table-container > table {
    margin-left: 1.1rem;
  }

  .bank-transfer-heading {
    font-size: 11px;
    width: 45%;
  }

  .bank-transfer-data {
    font-size: 13px;
  }

  .bank-transfer-notify-text {
    font-size: 8px;
  }

  .bank-transfer-pay-today-text {
    font-size: 14px;
  }

  .bank-transfer-amount-text {
    padding-top: 3px;
    padding-bottom: 18px;
  }

  .pay-now-button-container > button {
    width: 90%;
  }

  .bank-transfer-popup.continue .popup-container {
    width: 350px;
  }

  .bank-transfer-popup.continue .popup-container > div {
    max-width: 100%;
  }

  .banner-image {
    max-width: 100%;
    width: 346px;
    height: 170px;
  }

  .bank-transfer-thank-you {
    left: 25%;
    font-size: 40px;
    top: 37%;
    letter-spacing: 0.15rem;
  }

  .bank-transfer-once-cleared-text {
    font-size: 14px;
    line-height: 18px;
    padding-top: 10px;
  }

  .bank-transfer-more-funding {
    line-height: 18px;
    font-size: 12px;
    padding-top: 10px;
  }

  .bank-transfer-button-container > table > tr > td > button {
    font-size: 14px;
    width: 140px;
  }

  .bank-transfer-button-container > table > tr > td {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .coronis-pay-now-pay-later-form > div:last-child {
    padding: 2rem;
  }

  .elepay-footer-logo {
    width: 120px;
    left: 2rem;
    height: 30px;
  }
}

@media (min-width: 1500px) {
  .detailsContainer {
    height: calc(100vh + 50vh);
  }

  .details-container-floating {
    left: 25%;
  }

  .payment-container {
    margin-left: 25%;
  }

  .bank-transfer-thank-you {
    left: 42%;
    top: 30%;
  }
}
