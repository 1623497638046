.welcome-back-name {
  text-underline-position: under;
}
@media screen and (max-width: 1279px) {
  .headerBackground {
    background-image: url("../../assets/images/WelBackHeader.png");
    background-position: top;
    /* background-size: cover; */
    background-repeat: no-repeat;
    position: relative;
    background-size: 1440px;
  }
}
@media screen and (min-width: 1280px) {
  .headerBackground {
    background-image: url("../../assets/images/WelBackHeader.png");
    /* background-position: center; */
    /* background-size: cover; */
    background-repeat: round;
    position: relative;
    background-size: 2160px;
  }
}
