.messageBoxContainer {
  width: 700px;
  min-height: 556px;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: #fff;
  border-radius: 16px;
}
.messageBoxHeader {
  font-size: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 48px;
}
.paragraphBox {
  padding-top: 40px;
  text-align: left;
}
.messageBoxParagraph {
  font-size: 18px;
  line-height: 28px;
  padding-left: 48px;
  padding-right: 48px;
}
.closeButton {
  width: 97px;
  height: 48px;
  border: 1px solid;
  border-radius: 24px;
}
.footerBox {
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 80px;
}

@media screen and (max-width: 399px) {
  .messageBoxContainer {
    margin-left: 8px;
    margin-right: 8px;
    min-height: 0;
    height: 100%;
    margin-top: 20rem;
    margin-bottom: 10rem;
  }
  .messageBoxHeader {
    font-size: 32px;
    padding-left: 32px;
  }
  .messageBoxParagraph {
    font-size: 16px;
    line-height: 26px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .footerBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .footerBox > span {
    order: 2;
  }
  .footerBox > button {
    order: 1;
    width: 100%;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 400px) and (max-width: 1024px) {
  .messageBoxContainer {
    margin-left: 8px;
    margin-right: 8px;
  }
  .messageBoxHeader {
    font-size: 32px;
    padding-left: 32px;
  }
  .messageBoxParagraph {
    font-size: 16px;
    line-height: 26px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .footerBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 32px;
    padding-right: 32px;
  }
  .footerBox > span {
    order: 2;
  }
  .footerBox > button {
    order: 1;
    width: 100%;
    margin-bottom: 24px;
  }
}
